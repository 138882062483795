<template>
  <div class="container">
    <h3>Universities and colleges</h3>
    <div class="uni-main-container">
      <swiper
        id="universities-carousel"
        :slides-per-view="auto"
        :space-between="0"
        :modules="[Autoplay, FreeMode]"
        :autoplay="{
          disableOnInteraction:false,
          delay:0
        }"
        :free-mode="{
          enabled: true,
          momentum: false,
        }"
        :speed="5000"
        :loop="true"
        :one-way-movement="true"
        :prevent-clicks="true"
        :follow-finger="false"
        :resistance="false"
        :lazy-preload-prev-next="3"
        :load-prev-next="true"
        :class="{
          padding: '0 40px',
        }"
        :style="{
          maxHeight: '120px',
          overflow: 'hidden',
          width: '100%',
        }"
      >
        <swiper-slide
          v-for="(logo, index) in computedLogo"
          :key="index"
          :style="{
            maxHeight: isMobile ? '72px' : '120px',
            maxWidth: isMobile ? '153px' : '256px'
          }"
          :class="{
            'swiper-no-swiping': true,
          }"
        >
          <div class="uni-container">
            <img
              :src="logo.path"
              :alt="logo.name"
              loading="lazy"
            >
          </div>
          <div class="swiper-lazy-preloader" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { cdn } from '../../support/cdn';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import { auto } from '@popperjs/core';
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';

/**
 * List of university logos by order
 * 1. Sunway University
 * 2. Monash University
 * 3. University of Nottingham
 * 4. The One Academy
 * 5. INTI
 * 6. First City University
 * 7. University of Wollongong
 * 8. MMU
 * 9. UTAR
 */

interface University {
  name: string;
  path: string;
}

const uniLogos: University[] = [
  { name: 'Sunway University', path: cdn('logos/universities/sunway-uni.jpg') },
  { name: 'Monash University', path: cdn('logos/universities/monash-uni.jpg') },
  { name: 'University of Nottingham', path: cdn('logos/universities/nottingham-uni.jpg') },
  { name: 'The One Academy', path: cdn('logos/universities/the-one-academy.jpg') },
  { name: 'INTI', path: cdn('logos/universities/inti.jpg') },
  { name: 'First City University', path: cdn('logos/universities/first-city-uni.jpg') },
  { name: 'University of Wollongong', path: cdn('logos/universities/uni-of-wollongong.jpg') },
  { name: 'MMU', path: cdn('logos/universities/mmu.jpg') },
  { name: 'UTAR', path: cdn('logos/universities/utar.jpg') },
];

const isMobile = ref(false);

const computedLogo = computed<University[]>(() => {
  return [...uniLogos, ...uniLogos, ...uniLogos, ...uniLogos];
});

function onReSize() {
  if (window.innerWidth < 768) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
}

onBeforeMount(() => {
  onReSize();
  window.addEventListener('resize', onReSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onReSize);
});

</script>

<style scoped>
#universities-carousel .swiper-wrapper{
  transition-timing-function: linear !important;
}

h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.5px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 56px 0;
}

.uni-main-container {
    display: flex;
    width: 100%;
    max-height: 120px;
}

.uni-container {
    display: flex;
    justify-content: center;
    max-width: 256px;
    width: 100%;
    max-height: 120px;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 32px 0;
    }

    h3 {
        font-size: 24px;
        line-height: 32px;
    }
}
</style>
