<!--
This is a slot for the other components that will be added to the InternshipProgramContentTemplate.vue file.
-->
<template>
  <div
    class="main-container"
    :class="{
      'grey-bg' : props.isGreyBg,
      'bottom-padding' : props.withBottomPadding, 
      'left-right-padding' : props.withLeftRightPadding,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  isGreyBg: {
    type: Boolean,
    default: false
  },
  withBottomPadding: {
    type: Boolean,
    default: false
  },
  withLeftRightPadding: {
    type: Boolean,
    default: false
  }
});
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 160px;
}

.bottom-padding {
  padding-bottom: 160px;
}

@media screen and (max-width: 1024px) {
  .left-right-padding {
    padding-left: 48px;
    padding-right: 48px;
  }

  .main-container {
    padding-top: 64px;
  }
  .bottom-padding {
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 767px) {
  .left-right-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.grey-bg {
  background-color: #FAFAFA;
}
</style>
