<template>
  <div class="main-container">
    <div class="header">
      <h1>Work made fun ❤️</h1>
      <p>
        <span
          class="highlight"
          @click="handleClick"
        > Our team culture</span> is precious to us—our conversations extend beyond working hours and shape how we interact whenever we're together. At Alphapod, we foster an environment where meaningful relationships flourish and work becomes enjoyable.
      </p>
    </div>
    <swiper
      :slides-per-view="`auto`"
      :space-between="24"
      :free-mode="true"
      :modules="[FreeMode]"
      :slides-offset-after="swiperSlideOffset"
      :slides-offset-before="swiperSlideOffset"
      :lazy-preload-prev-next="3"
      :load-prev-next="true"
      :center-insufficient-slides="true"
      class="work-made-fun-container"
    >
      <swiper-slide
        v-for="workMadeFun in workMadeFuns"
        :key="workMadeFun.title"
        class="work-made-fun-card"
      >
        <img
          :width="iconWidth"
          :height="iconWidth"
          :src="icon(workMadeFun.icon).value"
          alt="icon"
        >
        <h4>{{ workMadeFun.title }}</h4>
        <p>{{ workMadeFun.description }}</p>
      </swiper-slide>
    </swiper>
    <div class="internship-container">
      <div
        v-for="job in props.internsJob"
        :key="job.title"
        class="internship-card"
        @click="onApplyInternJob(job)"
      >
        <div class="title">
          <h4>{{ job.title }}</h4>
          <p>Apply now</p>
        </div>
        <img
          width="16"
          height="16"
          :src="cdn('icons/arrow-right.svg')"
          alt="arrow"
        >
      </div>
    </div>
  </div></template>

<script lang="ts" setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { cdn } from '../../support/cdn';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import InternshipJob from '../../model/InternshipJob';

const emit = defineEmits(['applyInternJob']);

const iconWidth = ref(24); // mobile
const swiperSlideOffset = ref(24); // mobile:24, tablet:48

const props = defineProps<{
  internsJob: InternshipJob[];
}>();

interface WorkMadeFun {
  title: string;
  icon: string;
  description: string;
}

const workMadeFuns: WorkMadeFun[] = [
  {
    title: 'Work IRL',
    icon: 'trophy',
    description: 'Get hands-on experience with our live projects. Work alongside developers and designers on actual client projects throughout your internship.',
  },
  {
    title: 'Work & Play',
    icon: 'tennis',
    description: 'All work and no play isn’t our style! We make time for regular fun—whether it’s diving into board games or hitting the court for an exciting game of Pickleball, our goal is to create memorable, enjoyable experiences for everyone.',
  },
  {
    title: 'Real Impact',
    icon: 'globe',
    description: 'The work you do has a direct, real-world impact on users and stakeholders alike. Design and develop solutions that reach and benefit millions of users worldwide!',
  },
];

const icon = (iconName: string) => {
  return computed(() => {
    switch (iconName) {
      case 'trophy':
        return cdn('icons/icon_trophy.svg');
      case 'tennis':
        return cdn('icons/icon_table-tennis-solid.svg');
      case 'globe':
        return cdn('icons/icon_globe.svg');
      default:
        return '';
    }
  });
};

const handleClick = () => {
  window.location.href = '/about';
};

function onApplyInternJob(job: InternshipJob) {
  emit('applyInternJob', job);
}

function onResize() {
  if (window.innerWidth < 764) {
    iconWidth.value = 24;
    swiperSlideOffset.value = 24;
  } else if (window.innerWidth < 1024 ) {
    swiperSlideOffset.value = 48;
  } else {
    swiperSlideOffset.value = 24;
    iconWidth.value = 32;
  }
}

onBeforeMount(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

</script>

<style scoped>
span.highlight {
  color: #007AFF;
  line-height: 32px;
  font-size: 20px;
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;
  padding: 0 24px;
}

.header h1 {
  font-size: 48px;
  font-family: 'SF Compact', sans-serif;
  font-weight: 656;
  letter-spacing: -1px;
  text-align: center;
}

.header p {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  max-width: 1024px;
}

.internship-container {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.internship-card {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid #E6E6E6;
  padding: 16px 24px;
  max-width: 500px;
  width: 100%;
  border-radius: 12px;
  background-color: white;
  cursor: pointer;
}

.internship-card:hover {
  background-color: #f0f0f0;
}

.title {
  max-width: 420px;
}

.title h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.title p {
  font-size: 20px;
  line-height: 32px;
  color: #969696;
}

.work-made-fun-container {
  display: flex;
  gap: 24px;
  padding-top: 108px;
  padding-bottom: 64px;
  width: 100vw;
}

@media screen and (min-width: 1025px) {
  .work-made-fun-container {
    margin-left: -24px;
  }
}

.work-made-fun-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid #E6E6E6;
  padding: 32px;
  max-width: 426px;
  height: 426px;
  background-color: white;
}

.work-made-fun-card h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.25px;
}

.work-made-fun-card p {
  font-size: 20px;
  line-height: 32px;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  span.highlight {
    font-size: 16px;
  }

  .header {
    align-items: flex-start;
    gap: 24px;
    padding: 0 48px;
  }

  .header h1 {
    font-size: 32px;
  }

  .header p {
    font-size: 16px;
    line-height: 24px;
    text-align: start;
  }

  .internship-container {
    flex-direction: column;
    align-items: center;
    padding: 0 48px;
  }

  .internship-card {
    padding: 12px 16px;
    min-width: 0;
  }

  .title h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .title p {
    font-size: 14px;
    line-height: 20px;
  }

  .work-made-fun-container {
    padding-top: 64px;
  }

  .work-made-fun-card {
    padding: 24px;
    max-width: 310px;
    height: 380px;
  }

  .work-made-fun-card h4 {
    font-size: 20px;
    line-height: 24px;
  }

  .work-made-fun-card p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 0 24px;
  }

  .internship-container {
    padding: 0 24px;
  }
}
</style>
