<!--
Author: Haziq Kamel
Purpose: This component is designed to showcase the DVO project,
a daily Bible devotions app that was developed by a team of interns.
The component includes a title, feedback, and statistics about the app,
as well as an image and testimonial from one of the interns who worked on the project.

How to use this component:
1. Import the component into your desired Vue file:
  import DVOShowcase from 'path/to/DVOShowcase.vue';

2. Use the component in your template:
  <DVOShowcase />
-->

<template>
  <div class="container">
    <div class="left-sub-container">
      <div class="title rounded-container">
        <h1>DVO: Daily Bible Devotions</h1>
        <p>
          DVO began as an internship project built as a Minimum Viable Product (MVP) that evolved over time to become a full-fledged digital product. Since its launch, DVO has undergone multiple major iterations and has to date achieved over 600,000 downloads worldwide.
          <br><br>
          DVO’s journey exemplifies <span
            class="highlight"
            @click="goToServices"
          >our approach</span> to product development—transforming ideas into impactful products through design, development, and continuous improvement.
        </p>
      </div>
      <div class="feedback rounded-container">
        <div class="text-with-border">
          <p>Alphapod provided me with the perfect introduction to the mobile app and software industry!</p>
        </div>
        <div class="sub-feedback">
          <img
            src="https://res.cloudinary.com/alphapod/image/upload/v1526467701/alphapod_website/internjoeltay.png"
            alt="internjoeltay"
          >
          <div>
            <h3>Joel Tay</h3>
            <p>Former intern</p>
          </div>
        </div>
      </div>
      <div class="statistic rounded-container">
        <AnimatedNumberCount
          title="Downloads"
          :targeted-number="600000"
          padding-right
        />
        <AnimatedNumberCount
          title="Reviews"
          :targeted-number="1700"
          padding-in-between
        />
        <AnimatedNumberCount
          title="5 star reviews"
          :targeted-number="1200"
          padding-left
        />
      </div>
    </div>
    <div class="right-sub-container rounded-container">
      <swiper
        id="dvo-swiper-left"
        :direction="'vertical'"
        :space-between="0"
        :slides-per-view="'auto'"
        :free-mode="true"
        :modules="[FreeMode, Autoplay]"
        :autoplay="{
          delay: 0,
          disableOnInteraction: false,
        }"
        :speed="5000"
        :loop="true"
        :lazy-preload-prev-next="3"
        :load-prev-next="true"
        :auto-height="false"
        :style="{ maxHeight: '1048px', overflow: 'hidden' }"
        @swiper="onSwiperLeft"
      >
        <swiper-slide
          v-for="(image, index) in imageLeftLoop"
          :key="index"
          :style="{
            maxHeight: isMobile ? '282px' : '519px',
            maxWidth: isMobile ? '143px' : '263px'
          }"
          :class="{
            'swiper-no-swiping': true,
          }"
        >
          <img
            :src="image"
            alt="DVO Image"
            loading="lazy"
            :style="{
              maxHeight: isMobile ? '258px' : '100%',
              maxWidth: isMobile ? 'none' : '100%'
            }"
          >
          <div class="swiper-lazy-preloader" />
        </swiper-slide>
      </swiper>
      <swiper
        id="dvo-swiper-right"
        :direction="'vertical'"
        :space-between="0"
        :slides-per-view="'auto'"
        :free-mode="true"
        :modules="[FreeMode, Autoplay]"
        :autoplay="{
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: true,
        }"
        :speed="5000"
        :loop="true"
        :lazy-preload-prev-next="3"
        :load-prev-next="true"
        :auto-height="false"
        :style="{ maxHeight: '1048px', overflow: 'hidden' }"
        @swiper="onSwiperRight"
      >
        <swiper-slide
          v-for="(image, index) in imageRightLoop"
          :key="index"
          :style="{
            maxHeight: isMobile ? '282px' : '519px',
            maxWidth: isMobile ? '143px' : '263px'
          }"
          :class="{
            'swiper-no-swiping': true,
          }"
        >
          <img
            :src="image"
            alt="DVO Image"
            loading="lazy"
            :style="{
              maxHeight: isMobile ? '258px' : '100%',
              maxWidth: isMobile ? 'none' : '100%'
            }"
          >
          <div class="swiper-lazy-preloader" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { cdn } from '../../support/cdn';
import AnimatedNumberCount from './AnimatedNumberCount.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { Swiper as SwiperInstance } from 'swiper';

const isMobile = ref(window.innerWidth < 680);
const imageCountLeft = 3;
const imageCountRight = 3;
const imageLeft: string[] = [...Array(imageCountLeft).keys()].map((i) => cdn(`showcase/dvo/img_ap_internship_dvo${i + 1}.png`));
const imageRight: string[] = [...Array(imageCountRight).keys()].map((i) => cdn(`showcase/dvo/img_ap_internship_dvo${i + 4}.png`));
const imageLeftLoop = computed(() => [...Array(12).keys()].map(() => imageLeft).flat());
const imageRightLoop = computed(() => [...Array(12).keys()].map(() => imageRight).flat());

const swiperInstanceLeft = ref<SwiperInstance | null>(null);
const swiperInstanceRight = ref<SwiperInstance | null>(null);

function onSwiperLeft (swiper: SwiperInstance) {
  swiperInstanceLeft.value = swiper;
  if (swiperInstanceLeft.value) {
    setTimeout(() => {
      if (!swiperInstanceLeft.value?.autoplay?.running) {
        swiperInstanceLeft.value?.autoplay?.start();
      }
    }, 150);
  }
}

function onSwiperRight (swiper: SwiperInstance) {
  swiperInstanceRight.value = swiper;
  if (swiperInstanceRight.value) {
    setTimeout(() => {
      if (!swiperInstanceRight.value?.autoplay?.running) {
        swiperInstanceRight.value?.autoplay?.start();
      }
    }, 150);
  }
}

function onResize() {
  if (window.innerWidth < 680) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

function goToServices() {
  window.location.href = '/services';
}
</script>

<style scoped>
#dvo-swiper .swiper-wrapper{
  transition-timing-function: linear !important;
}

span.highlight {
  color: #007AFF;
  cursor: pointer;
}

.rounded-container {
  background-color: #FAFAFA;
  border-radius: 16px;
  border: 1px solid #E6E6E6;
}

.statistic {
  display: flex;
  padding: 32px 40px;
  justify-content: space-around;
  overflow-x: auto;
  scrollbar-width: none;
  min-height: 140px;
}

.statistic::-webkit-scrollbar {
  display: none;
}

.feedback {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 64px 40px;
}

.sub-feedback {
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  margin: 0 24px;
}

.sub-feedback h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.sub-feedback p {
  font-size: 20px;
  line-height: 32px;
}

.text-with-border {
  display: flex;
  border-left: 2px solid #00CEB0;
}

.text-with-border p {
  font-size: 20px;
  line-height: 32px;
  padding-left: 24px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 64px 40px;
}

.title h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
}

.title p {
  font-size: 20px;
  line-height: 28px;
}

.left-sub-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 628px;
  width: 100%;
}

.right-sub-container {
  display: flex;
  flex-direction: row;
  max-width: 628px;
  width: 100%;
  padding: 0 63px;
}

.container {
  display: flex;
  gap: 24px;
  max-width: 1280px;
  width: 100%;
  max-height: 1048px;
  height: 100%;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .title {
    padding: 32px;
  }

  .container {
    max-height: none;
  }

  .title h1 {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.75px;
  }

  .title p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .text-with-border p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .feedback {
    padding: 32px 16px;
  }

  .sub-feedback {
    gap: 16px;
  }

  .sub-feedback h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .sub-feedback p {
    font-size: 14px;
    line-height: 20px;
  }

  .sub-feedback img {
    width: 40px;
    height: 40px;
  }

  .statistic {
    padding: 24px 32px;
    align-items: center;
    min-height: 0;
  }
}

@media screen and (max-width: 1024px) {
  .right-sub-container {
    max-height: 628px;
  }

  .container {
    flex-direction: column-reverse;
    align-items: center;
    max-height: none;
  }
}

@media screen and (max-width: 425px) {
  .right-sub-container {
    padding: 0 40px;
  }

  .statistic {
    justify-content: stretch;
  }

  .right-sub-container {
    max-height: 428px;
  }
}

@media screen and (max-width: 375px) {
  .right-sub-container {
    padding: 0 28px;
  }
}

@media screen and (max-width: 320px) {
  .right-sub-container{
    padding: 0 0;
  }
}

</style>
