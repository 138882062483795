<template>
  <div
    v-if="props.descriptions.length != 0"
    class="row-container"
    :class="{ 'top-padding': props.withTopPadding }"
  >
    <h3>{{ props.title }}</h3>
    <div
      v-for="description in props.descriptions"
      :key="description"
      class="row-description"
    >
      <div
        :style="{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '4px',
        }"
      >
        <div />
        <svg
          :width="iconWidth"
          :height="iconWidth"
          :viewBox="`0 0 20 20`"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            :style="{ fill: props.isHovered ? '#000000' : '#C8C8C8' }"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.52119 8.82234L5 10.3563L8.92125 14L15 7.41705L13.3623 6L8.80041 10.9402L6.52119 8.82234Z"
            fill="white"
          />
        </svg>
      </div>
      
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
const props = defineProps<{
  title: string;
  descriptions: string[];
  withTopPadding?: boolean;
  isHovered?: boolean;
}>();

const iconWidth = ref(20);

function onResize() {
  if (window.innerWidth <= 768) {
    iconWidth.value = 14;
  } else {
    iconWidth.value = 20;
  }
}

onBeforeMount(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

</script>

<style scoped>
svg {
  display: block; /* Prevent inline SVG rendering issues */
}

svg path {
  transition: fill 0.3s;
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

hr {
  height: 1px;
  color: black;
  width: 100%;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

p {
  font-size: 20px;
  line-height: 32px;
  margin: 0;
}

.top-padding {
  padding-top: 32px;
  border-top: 1px solid #E6E6E6;
}

.row-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 12px;
}

.row-description {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  h3 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }

  .top-padding {
    padding-top: 24px;
  }
}
</style>

