<template>
  <div>
    <div class="container">
      <h1>Goodbye uni, hello world.</h1>
      <p>Our internship program is more than just a learning opportunity—it’s a springboard for your career and personal growth. Designed for passionate and curious minds, our program offers hands-on experience in cutting-edge technologies, from web and mobile app development to UI/UX design. Interns at Alphapod work alongside our expert team on real-world projects, gaining invaluable insights into the tech industry while honing their skills in a friendly and collaborative environment. Whether you’re a student, recent graduate, or someone looking to begin your journey in the world of design and technology, Alphapod is a great place to start.</p>
    </div>
    <div class="image-container">
      <canvas
        ref="canvas"
        :width="canvasSize.width"
        :height="canvasSize.height"
        :style="{
          width: `${canvasSize.width}px`,
          height: `100%`,
        }"
      />
    </div>
   
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { cdn } from '../../support/cdn';
import { Rive } from '@rive-app/canvas';

const rocketmanRiv = cdn('rive/rocketman_v3.riv');
const canvas = ref<HTMLCanvasElement | null>(null);
const riveInstance = ref<Rive | null>(null);

const canvasSize = ref({
  width: 720,
  height: 720,
});

function onResize() {
  if (window.innerWidth < 767) {
    canvasSize.value.width = 342;
    canvasSize.value.height = 342;
  } else if (window.innerWidth <= 1024) {
    canvasSize.value.width = 540;
    canvasSize.value.height = 540;
  } else {
    canvasSize.value.width = 720;
    canvasSize.value.height = 720;
  }

  if (canvas.value && riveInstance.value) {
    initRive();
  }
}

function initRive() {
  if (canvas.value) {
    riveInstance.value = new Rive({
      src: rocketmanRiv,
      canvas: canvas.value,
      autoplay: true,
    });
  }
}

onBeforeMount(() => {
  onResize();
});

onMounted(() => {
  window.addEventListener('resize', onResize);
  initRive();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});

</script>

<style scoped>
.container {
  max-width: 720px;
  gap: 32px;
  display: flex;
  flex-direction: column;
}

.image-container {
  display: flex;
  justify-content: center;
  padding: 80px 0 120px 0;
  width: 100%;
}

h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  font-style: normal;
  letter-spacing: -3px;
}

p {
  font-size: 20px;
  line-height: 32px;
}

@media screen and (max-width: 768px) {
  .container {
    gap: 24px;
  }

  .image-container {
    padding: 64px 0;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
  }
}
</style>
