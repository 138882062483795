<template>
  <div class="main-container">
    <div class="sub-container">
      <h1 class="title">
        Internship Program
      </h1>
      <h3 class="title">
        Springboard into the world of leading-edge design and technology.
      </h3>
    </div>
    <img
      v-if="props.headerImage"
      :src="props.headerImage"
      alt="Internship Program Header Image"
    >
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  headerImage?: string;
}>();
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container img {
  object-fit: cover;
  width: 100%;
  margin: 80px 0 0 0;
  aspect-ratio: 12/5;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  gap: 68px;
}

h1.title {
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -5px;
  line-height: 120px;
}

h3.title {
  font-size: 20px;
  line-height: 32px;
}

@media screen and (max-width: 1024px) {
  h1.title {
    font-size: 48px;
    line-height: normal;
    letter-spacing: -1px;
  }

  h3.title {
    font-size: 20px;
    line-height: 24px;
  }
  .sub-container {
    padding: 0 48px;
    gap: 8px;
  }
}

@media screen and (max-width: 767px) {
  .main-container img {
    margin: 64px 0 0 0;
    aspect-ratio: 1.5;
  }

  h3.title {
    font-size: 16px;
    line-height: 24px;
  }

  .sub-container {
    padding: 0 24px;
    gap: 8px;
  }
}
</style>
