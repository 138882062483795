<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: Project Page

Description:
This component displays the left and right sections of the project challenge section of the project page.

How to use it:
<ProjectChallenge
  :data="data"
/>
-->
<template>
  <section
    ref="target"
    class="main-container"
    :style="`background-color: ${backgroundColor};`"
  >
    <section
      ref="leftContainer"
      class="scroll-container left-container"
    >
      <template
        v-for="(content, index) in leftSection"
        :key="`left-${index}`"
      >
        <div
          v-if="content.type === 'image'"
          class="content-item"
        >
          <img
            :id="content.id"
            :src="content.src"
            :alt="content.alt"
            style="width: 100%;"
          >
        </div>

        <div
          v-if="content.type === 'text'"
          class="content-item sticky-text"
        >
          <h2>
            {{ content.title }}
          </h2>
          <p
            v-for="(paragraph, pIndex) in content.description"
            :key="`left-text-${pIndex}`"
          >
            {{ paragraph }}
          </p>
          <div
            v-if="content.appstore_link || content.playstore_link"
            class="cta-section"
          >
            <h3>Download the app</h3>
            <div class="button-section">
              <a
                v-if="content.appstore_link"
                :href="content.appstore_link"
                target="_blank"
                rel="noopener"
              >
                <img
                  class="cta-button"
                  :src="$cdn('project/button_appstore.svg')"
                >
              </a>
              <a
                v-if="content.playstore_link"
                :href="content.playstore_link"
                target="_blank"
                rel="noopener"
              >
                <img
                  class="cta-button"
                  :src="$cdn('project/button_playstore.svg')"
                >
              </a>
            </div>

          </div>
          <div
            v-if="content.website_link"
            class="website-link"
            v-html="content.website_link"
          />
        </div></template>
    </section>
    <section
      v-if="!isMobile"
      class="scroll-container right-container"
    >
      <section
        ref="parallax"
        class="parallax-content"
      >
        <div
          v-for="(content, index) in rightSection"
          :id="`right-${index}`"
          :key="`right-${index}`"
          class="right-content"
        >
          <div
            v-if="content.type === 'image'"
            :class="`content-item item-${index}`"
          >
            <img
              :id="content.id"
              :src="content.src"
              :alt="content.alt"
              style="width: 100%;"
            >
          </div>

          <div
            v-else-if="content.type === 'text'"
            :class="`content-item item-${index} sticky-text`"
          >
            <h2>
              {{ content.title }}
            </h2>
            <p
              v-for="(paragraph, pIndex) in content.description"
              :key="`right-text-${pIndex}`"
            >
              {{ paragraph }}
            </p>
          </div>
        </div>
      </section>
    </section>
    <swiper
      v-else
      :grab-cursor="true"
      :centered-slides="true"
      :slides-per-view="`auto`"
      :pagination="false"
      :initial-slide="1"
      :space-between="14"
      :lazy-preload-prev-next="3"
      class="mySwiper"
    >
      <swiper-slide
        v-for="(content, index) in rightSection"
        :key="index"
      >
        <img
          v-if="content.type === 'image'"
          class="content-item"
          :src="content.src"
          :alt="content.alt"
        >
        <div
          v-else-if="content.type === 'text'"
          class="content-item sticky-text"
        >
          <h2>
            {{ content.title }}
          </h2>
          <p
            v-for="(paragraph, pIndex) in content.description"
            :key="`right-text-${pIndex}`"
          >
            {{ paragraph }}
          </p>
        </div></swiper-slide>
    </swiper>
  </section>
</template>


<script setup lang="ts">

import { ref, onMounted } from 'vue';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { computed } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { uuid } from 'vue3-uuid';

gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
});

const backgroundColor = ref(props.data.background_color);

const process = (data: any) => {
  if(data.acf_fc_layout === 'image') {
    return {
      type: data.acf_fc_layout,
      src: data.image.url,
      alt: data.image.alt,
      ratio: data.image.width / data.image.height,
      id: uuid.v4()
    };
  }else{
    return {
      type: data.acf_fc_layout,
      title: data.title,
      description: data.descriptions.map((item: any) => item.description),
      appstore_link: data.apple_app_store_link,
      playstore_link: data.google_play_store_link,
      website_link: data.website_link,
    };
  }
};

const leftSection = computed(() => {
  return props.data.first_section.children.map(process);
});

const rightSection = computed(() => {
  return props.data.second_section.children.map(process);
});

const isMobile = computed(() => {
  return window.innerWidth <= 1024;
});

const target = ref(null);
const parallax = ref(null);
const leftContainer = ref(null);

const totalRightHeight = computed(() => {
  return rightSection.value.reduce((acc: any, item: any) => {
    if(item.type === 'text') {
      return acc + item.description.length * 32;
    }else{
      const h =  getWidth() / item.ratio;
      return acc + h;
    }
  }, 0);
});

const getWidth = () => {
  const total = (window.innerWidth - 144) / 2;
  return total > 600 ? 600 : total;
};

const totalLeftHeight = computed(() => {
  return leftSection.value.reduce((acc: any, item: any) => {
    if(item.type === 'text') {
      return acc + item.description.length * 32;
    }else{
      const h = getWidth() / item.ratio;
      return acc + h;
    }
  }, 0);
});

onMounted(() => {
  if (target.value && !isMobile.value){
    let value = totalRightHeight.value;
    if (totalLeftHeight.value > totalRightHeight.value) {
      value = totalLeftHeight.value;
    }
    const y = 0.1 * value;
    // target.value.style.height = `${value}px`;
    gsap.to(parallax.value, {
      y: y * -1,
      ease: 'none',
      scrollTrigger: {
        trigger: parallax.value,
        endTrigger: target.value,
        start: 'top 80%',
        end: 'bottom bottom',
        scrub: true,
      }
    });

    if (totalLeftHeight.value < 200) {
      gsap.to(leftContainer.value, {
        y: y,
        ease: 'none',
        scrollTrigger: {
          trigger: leftContainer.value,
          endTrigger: target.value,
          start: 'top 80%',
          end: 'center bottom',
          scrub: true,
        }
      });
      if (leftSection.value) {
        leftSection.value.forEach((element: any) => {
          if (element.type === 'image') {
            const ele = document.getElementById(element.id);
            gsap.fromTo(ele, {
              scale: 1,
              y: 0
            } ,{
              scale: 1.1,
              y: 20,
              scrollTrigger: {
                trigger: ele,
                start: 'top bottom',
                end: 'bottom 20%',
                scrub: true,

              }
            });
          }
        });
      }
    }

    if (rightSection.value){
      rightSection.value.forEach((element: any) => {
        if (element.type === 'image') {
          const ele = document.getElementById(element.id);
          gsap.fromTo(ele, {
            y: 20,
            scale: 1.1
          }, {
            y: 0,
            scale: 1,
            scrollTrigger: {
              trigger: ele,
              start: 'top bottom',
              end: 'bottom 20%',
              scrub: true,
            }
          });
        }
      });
    }
  }
});

</script>
<style scoped>
.main-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #FAFAFA;
  gap: 80px;
  overflow: hidden;
}

.scroll-container {
  flex: 1;
  overflow: hidden;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.left-container{
  max-width: 600px;
  width: 100%;
  padding-top: 120px;
  margin-left: 32px;

}

.cta-section {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.cta-section h3 {
  color: #242424;
  font-family: 'Inter' sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 16px;
}

.website-link {
  font-family: 'Inter' sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 64px;
  margin-bottom: 120px;
}

.button-section {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.button-section .cta-button{
  margin: 0;
}

.right-container{
  max-width: 600px;
  width: 100%;
  padding-top: 120px;
  margin-right: 32px;
}

.right-content {
  display: flex;
  flex-direction: column;
}

.content-item img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 32px;
}

.parallax-content {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.sticky-text{
  margin-bottom: 160px;
}

.content-item h2{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1px;
  padding-bottom: 32px;
  padding-top: 120px;
}

.content-item p{
  color: #242424;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding-bottom: 32px;
}

@media screen and (max-width: 1024px) {
  .main-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0;
  }

  .mySwiper{
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 64px;
  }

  .swiper-slide{
    width: 60vw !important;
    height: auto !important;
  }

  .content-item img {
    height: 100%;
    object-fit: contain;
    margin-bottom: 0;
  }

  .scroll-container {
    width: 100%;
    max-height: none;
    margin: 24px 24px;
  }

  .content-item {
    margin: 0;
  }

  .left-container{
    display: flex;
    flex-direction: column;
    padding: 64px 24px;
    margin: 0;
    gap: 64px;
  }

  .content-item h2{
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: -0.75px;
    text-align: left;
    padding-top: 0;
    padding-bottom: 24px;
  }

  .content-item p{
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    padding-bottom: 24px;
  }

  .cta-section h3 {
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-bottom: 16px;
  }

  .website-link {
    font-family: 'Inter' sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    margin-top: 48px;
    margin-bottom: 120px;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .swiper-slide{
    width: 80vw !important;
  }
}
</style>
