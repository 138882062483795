<template>
  <div
    :id="`id-${props.job.title}`"
    class="card-container"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="card-content"
      :style="{
        height: (!isSeeMore && isMobile) ? '582px' : '100%',
        overflow: (!isSeeMore && isMobile) ? 'hidden': '',
        transition: 'all 0.2s cubic-bezier(0, 1, 0, 1)',
        WebkitTransition: 'all 0.2s cubic-bezier(0, 1, 0, 1))',
        position: 'relative',
      }"
    >
      <div class="card-title">
        <img
          :width="iconWidth"
          :height="iconWidth"
          :src="icon"
          alt="icon"
        >
        <div class="job-title">
          <h1>{{ props.job.title }}</h1>
          <div class="city-chip">
            <p>
              {{ props.job.city }}
            </p>
          </div>
        </div>
      </div>
      <!-- Responsibilities, Requirement, extra points, offer -->
      <div
        class="internship-card-row"
        :style="{
          paddingTop: '40px'
        }"
      >
        <InternshipCardRow
          title="Responsibilities"
          :descriptions="props.job.responsibilities"
          :is-hovered="isHovered"
        />
        <InternshipCardRow
          title="Requirements"
          :descriptions="props.job.requirements"
          :with-top-padding="true"
          :is-hovered="isHovered"
        />
        <InternshipCardRow
          title="For extra brownie points"
          :descriptions="props.job.extraPoints"
          :with-top-padding="true"
          :is-hovered="isHovered"
        />
        <InternshipCardRow
          title="What we offer"
          :descriptions="props.job.weOffer"
          :with-top-padding="true"
          :is-hovered="isHovered"
        />
      </div>
      <!-- See More -->
      <div
        v-if="isMobile"
        class="see-more-section"
        :style="{
          background: !isSeeMore ? 'linear-gradient(0deg, #FFF 24.62%, rgba(255, 255, 255, 0.00) 100%)' : '',
        }"
      >
        <a
          style="cursor: pointer"
          @click="onSeeMoreClicked"
        >
          {{ isSeeMore ? 'See less' : 'See more' }}
        </a>
        <img
          :width="24"
          :height="24"
          :src="cdn('icons/icon_caret_down_blue.svg')"
          alt="icon"
          :style="{
            transform: !isSeeMore ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease'
          }"
        >
      </div>
      <div
        v-show="isSeeMore && isMobile"
        :style="{
          height: '72px'
        }"
      />
    </div>
    <!-- Apply Now button -->
    <div
      class="button-container"
    >
      <div
        class="apply-button"
        @click="onJobApply"
      >
        Apply now
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { cdn } from '../../support/cdn';
import InternshipCardRow from './InternshipCardRow.vue';
import InternshipJob from '../../model/InternshipJob';

const emit = defineEmits(['onClickSeeMore', 'applyInternJob']);

const isHovered = ref(false);

const props = defineProps<{
  job: InternshipJob;
}>();

let icon = computed(() => {
  const category = props.job.category;

  switch (category) {
    case 'Design':
      return cdn('icons/icon_brush.svg');
    case 'IT':
      return cdn('icons/icon_cloud-code.svg');
    default:
      return cdn('icons/icon_brush.svg');
  }
});

const iconWidth = ref(40);
const isMobile = ref(false);
const isSeeMore = ref(false);

function onResize() {
  if (window.innerWidth < 769) {
    iconWidth.value = 24;
    isMobile.value = true;
  } else {
    iconWidth.value = 40;
    isMobile.value = false;
  }
}

function onJobApply() {
  emit('applyInternJob', props.job);
}

function onSeeMoreClicked() {
  emit('onClickSeeMore');
  isSeeMore.value = !isSeeMore.value;
}

function handleMouseEnter() {
  if (!isMobile.value) {
    isHovered.value = true;
  }
}

function handleMouseLeave() {
  if (!isMobile.value) {
    isHovered.value = false;
  }
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped>
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 582px;
}

.fadeHeight-enter,
.fadeHeight-leave-to
{
  max-height: 100%;
}

.see-more-section {
  display: flex;
  justify-content: center;
  padding: 24px 0;
  gap: 4px;
  width: 100%;
  align-items: end;
  position: absolute;
  height: 200px;
  bottom: 0;
}

.see-more-section a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #007AFF;
}

.apply-button {
  width: 100%;
  background: #007AFF;
  padding: 16px 24px;
  text-align: center;
  border-radius: 12px;
  font-size: 20px;
  line-height: 32px;
  color: #FFF;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.apply-button:hover {
  opacity: 0.8;
}

.button-container {
  display: flex;
  padding: 32px;
  justify-content: center;
}

.city-chip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #E6E6E6;
  max-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 8px;
}

.city-chip p {
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.25px;
  color: #646464;
}

.card-title {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 32px;
}

.card-title h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
}

.card-content {
  display: flex;
  flex-direction: column;
  margin: 32px 40px;
}

.card-container {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #E6E6E6;
  background-color: #FFF;
  justify-content: flex-end;
  width: 100%;
  max-width: 628px;
}

.card-container:hover {
  background-color: #FFF;
  transition: background-color 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .card-container:hover {
    background-color: #FFF;
    transition: none;
  }
}

.job-title {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.internship-card-row {
  display: flex;
  flex-direction: column;
  gap:32px;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .internship-card-row {
    gap: 24px;
  }

  .card-content {
    margin: 32px 16px 0;
  }

  .card-title {
    gap: 24px;
  }

  .card-title h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .city-chip p {
    font-size: 14px;
    line-height: 20px;
  }

  .button-container {
    padding: 16px;
  }

  .apply-button {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    padding: 12px 24px;
  }
}
</style>
