<!--
Author: Haziq Kamel
Purpose: This component is designed to animate the counting of numbers,
typically used to display statistics or other numerical data in an engaging way.

How to use this component:
1. Import the component into your desired Vue file:
  import AnimatedNumberCount from 'path/to/AnimatedNumberCount.vue';

2. Use the component in your template:
  <AnimatedNumberCount
    title="Your Title"
    :targetedNumber="1000"
  />

Props:
- title: String (required) - The title to display above the animated number.
- targetedNumber: Number (required) - The number to count up to.
- paddingRight: Boolean (optional) - Adds padding to the right.
- paddingInBetween: Boolean (optional) - Adds padding in between.
- paddingLeft: Boolean (optional) - Adds padding to the left.
-->

<template>
  <div
    ref="animatedNumberContainer"
    class="animated-container"
    :class="{'padding-right' : props.paddingRight,
             'padding-in-between' : props.paddingInBetween,
             'padding-left' : props.paddingLeft
    }"
    :style="{
      'border-right': props.paddingRight ? '1px solid #E6E6E6' : 'none',
      'border-left': props.paddingLeft ? '1px solid #E6E6E6' : 'none'
    }"
  >
    <p>{{ props.title }}</p>
    <h3>{{ formattedNumber }}</h3>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps<{
  title: string;
  targetedNumber: number;
  paddingRight?: boolean;
  paddingInBetween?: boolean;
  paddingLeft?: boolean;
}>();

const animatedNumber = ref(0);
const duration = 2000; // 5 seconds
const intervalTime = 60; // 60 milliseconds
const steps = duration / intervalTime; // Total number of steps
const incrementStep = Math.round(props.targetedNumber / steps); // Increment step per interval


const formattedNumber = computed(() => {
  if (animatedNumber.value >= 1000000000) {
    return (animatedNumber.value / 1000000000).toFixed(1) + 'B';
  } else if (animatedNumber.value >= 1000000) {
    const number = (animatedNumber.value / 1000000).toFixed(1);
    // If the number is a whole number, remove the decimal point
    return number.endsWith('.0') ? number.slice(0, -2) + 'M' : number + 'M';
  } else if (animatedNumber.value >= 100000) {
    return (animatedNumber.value / 1000).toFixed(0) + 'k+';
  } else if (animatedNumber.value >= 1000) {
    return animatedNumber.value.toLocaleString();
  } else {
    return animatedNumber.value.toLocaleString();
  }
});

const animatedNumberContainer = ref<HTMLElement | null>(null);

let observer: IntersectionObserver;

onMounted(() => {
  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const interval = setInterval(() => {
          if (animatedNumber.value < props.targetedNumber) {
            animatedNumber.value += incrementStep;
            if (animatedNumber.value > props.targetedNumber) {
              animatedNumber.value = props.targetedNumber;
            }
          } else {
            animatedNumber.value = props.targetedNumber;
            clearInterval(interval);
          }
        }, intervalTime);
        observer.unobserve(entry.target);
      }
    });
  });

  if (animatedNumberContainer.value) {
    observer.observe(animatedNumberContainer.value);
  }
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<style scoped>
.border-left-right {
  border-left: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
}

.padding-right {
  padding-right: 24px;
}

.padding-in-between {
  padding: 0 24px;
}

.padding-left {
  padding-left: 24px;
}

.animated-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.animated-container h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.5px;
}

.animated-container p {
  font-size: 20px;
  line-height: 32px;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .animated-container {
    max-height: 60px;
  }

  .animated-container h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .animated-container p {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
