<!--
Author: Eddy <eddy@alphapod.com>
Contributor: Add your name here if you edit this file
Module: General Components
Description:
This component provides a placeholder to display Lottie animations, with error handling and loading states.
It includes different placeholders for loading, errors, or if animation data is not available.
The animation can be set to autoplay and loop as per the provided props.

How to use it:
Include this component in your Vue application to show a Lottie animation. You need to provide the URL
to the animation JSON and a placeholder image URL to show during loading or in case of an error.

Props:
- `url`: URL to fetch the Lottie animation data (required).
- `placeholderUrl`: URL of the placeholder image displayed during loading or in case of an error (required).
- `autoplay`: Boolean value indicating whether the animation should autoplay (default: true).
- `loop`: Boolean value indicating whether the animation should loop (default: true).

Example usage:
<LottieObj
  url="https://example.com/animation.json"
  placeholderUrl="/images/loading-placeholder.png"
  :autoplay="true"
  :loop="false"
/>
-->

<template>
  <div>
    <!-- Loading Placeholder -->
    <div
      v-if="isLoading"
      class="loading-placeholder tw-flex tw-w-[250px] tw-h-[250px] tw-items-center tw-justify-center"
    />

    <!-- Error Placeholder -->
    <div
      v-else-if="hasError"
      class="error-placeholder"
    >
      <img :src="props.placeholderUrl">
    </div>

    <!-- Animation Data Placeholder (if animationData is null) -->
    <div
      v-else-if="!animationData"
      class="animation-data-placeholder"
    >
      <img :src="props.placeholderUrl">
    </div>

    <!-- Lottie Animation (shown when not loading and no error) -->
    <LottieAnimation
      v-else
      ref="anim"
      :animation-data="animationData"
      :auto-play="autoplay"
      :loop="loop"
      class="lottie-obj"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { LottieAnimation } from 'lottie-web-vue';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  placeholderUrl: {
    type: String,
    required: true,
  },
  autoplay:{
    type: Boolean,
    default: true,
  },
  loop:{
    type: Boolean,
    default: true,
  }
});

const animationData = ref(null);
const isLoading = ref(false);
const hasError = ref(false);

const loadAnimationData = () => {
  isLoading.value = true; // Set loading to true when the request starts
  axios.get(props.url)
    .then(response => {
      animationData.value = response.data;
      hasError.value = false; // Reset error state on successful fetch
    })
    .catch(error => {
      console.error('Error fetching the animation data:', error);
      hasError.value = true; // Set error state on failure
    })
    .finally(() => {
      isLoading.value = false; // Set loading to false when the request completes
    });
};

onMounted(loadAnimationData);
</script>

<style>
</style>
